import Iconify from "../components/Iconify";
import React from 'react';
const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

export const navConfig = [
 { 
    title: 'Dashboard',
    path: '/',
    icon: getIcon('mdi-light:home'),
  },
  {
    title: 'Account',
    path: 'account',

    icon: getIcon('mdi-light:account'),
  },
  {
    title: 'Users',
    path: 'users',
    icon: getIcon('mdi-light:account'),
  },
{
    title: 'Setting',
    path: 'setting',
    icon: getIcon('ep:setting'),
},
];
  