import axios from "axios";
import AuthService from "../auth/auth.service";
import baseURL from "../url";
const getAllAssignment = async () => {
    const data  = await axios.get(baseURL + '/api/allAssignment/');
        return data;
      };
     const AssignmentById = async (id) => {
        const data  = await axios.get(baseURL + `/api/assignmetDetail/${id}`);
            return data;
          };  
          const getAllAssignmentById = async (id) => {
            const data  = await axios.get(baseURL + `/api/itemsByAssignmetId/${id}`);
                return data;
              };
          const getAllCheckListAsseignmentById = async (id) => {
            const data  =  await  axios.get(baseURL + `/api/assignmetChckList/${id}`);
                return data;
              };
     const getAssignmentByDate = async (body) => {
      const currentuser = AuthService.getCurrentUser();
      console.log("sadguru2");
      console.log(body);
      const createdDate = body;
      console.log("data");
     const data  =  await  axios.get(baseURL + '/api/getAssignmentByDate/',{
      params: {
        createdDate: createdDate,
        userId: currentuser.id 
      }
     });
     return data;
    };
    const userAssignment = async (data) => {
 console.log("kebede" + data)
 console.log("kebede")
     const data1 =  await  axios.get(baseURL + '/api/getAssignmentByDate/',{
      params: {
        userId: data.userId,
        createdDate:data.createdDate,
      }
     });
     return data1;
    };
    const getAssignmentByDateAll = async (body) => {
      const currentuser = AuthService.getCurrentUser();
      console.log("sadguru2");
      console.log(body);
      const createdDate = body;
      console.log("data");
     const data  =  await  axios.get(baseURL + '/api/getAssignmentByUserId',{
      params: {
        userId: currentuser.id,
        createdDate:createdDate,
      }
     });
     return data;
    };
    const getAllAssignmentByUserId = async (body) => {
      const currentuser = AuthService.getCurrentUser();
      console.log("sadguru2");
      console.log(body);
      const createdDate = body;
      console.log("data");
     const data  =  await  axios.get(baseURL + '/api/getAllAssignmentByUserId',{
      params: {
        userId: 3,
        createdDate:"2023-01-27"
      }
     });
     return data;
    };        
  const updateCheckList =  async (body) => {
                const data  =  await axios.put(baseURL + '/api/updateCheckList',body);
                        return data;
                };
                const updateAssignment =  async (body,fltType) => {
                  const data  =  await axios.put(baseURL + '/api/updateAssignment',{body,fltType});
                          return data;
                  };
const Assignment = {
    getAllAssignment,
    getAllAssignmentById,
    getAllCheckListAsseignmentById,
    updateCheckList,
    getAssignmentByDate,
    getAssignmentByDateAll,
    userAssignment,
    getAllAssignmentByUserId,
    AssignmentById,
    updateAssignment
}
export default Assignment;


