import React, { useState } from 'react';
import { Grid,Box } from '@mui/material'
import Button from '../../controls/Button'
import Input from '../../controls/Input'
import { Form, useForm } from '../../controls/useForm'
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import AssignmentQuery from '../../api/assignment/assignment.service';
import ItemQuery from '../../api/item/itemService';
export default function ItemForm(props) {
    const { addOrEdit, recordForEdit } = props
    const {saveItem,message,setMessag} = ItemQuery();
    const [selectorValue,setSelectorValue] = useState("")
    console.log(props.id)
    const id = props.id;
    const initialFValues = {
        assignmentId:id,
        sn: '',
        pallet_number: '',
        awb_number: '',
        piece: '',
        remark: '',
         selectorValues: [],
         weight:'',
         
    }
    const validate = (fieldValues = values) => {
        let temp = { ...errors }
           /* if ('sn' in fieldValues)
            temp.sn = fieldValues.sn ? "" : "This field is required."
            if ('pallet_number' in fieldValues)
            temp.pallet_number = fieldValues.pallet_number ? "" : "This field is required."*/
            if ('awb_number' in fieldValues) 
            temp.awb_number = fieldValues.awb_number ? "" : "This field is required."
           /* if ('piece' in fieldValues)
            temp.piece = fieldValues.piece ? "" : "This field is required."
            if ('weight' in fieldValues) */
         /*   temp.weight = fieldValues.weight ? "" : "This field is required."
            if ('remark' in fieldValues)
            temp.remark = fieldValues.remark ? "" : "This field is required."
            if ('bchName' in fieldValues)
            temp.bchName = fieldValues.bchName ? "" : "This field is required." */
        setErrors({
            ...temp
        })
       
        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    }
    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm,
        selectorValues
    } = useForm(initialFValues, true, validate);
    const handleSubmit = e => {
        e.preventDefault()
        if (validate()) {
            saveItem(values);
        console.log(values);
         resetForm();
        }
    }
   // const  selectorValues  = "";
    const removeSelectorValue = (index) =>{
      
     // { selectorValue: value }
      }
     const  handleSelectorValueChange = ({ target: { value } }) => {
        //makes separate copy of array.
       // setState({ selectorValue: value });
      // { selectorValue: value }
       setSelectorValue(value);
      };
return (
        <Form onSubmit={handleSubmit}>
            <Grid container>
                <Grid item xs={12}>
                    <Input
                        name="sn"
                        label="Please Enter the 
                        SN"
                        value = {values.sn}
                        onChange={handleInputChange}
                        error = {errors.sn}
                    />                      
                    <Input
                        name="pallet_number"
                        label="Please Enter the  Pallet Number"
                        value = {values.pallet_number}
                        onChange={handleInputChange}
                        error = {errors.pallet_number}
                    />  
                     <Input
                        name="awb_number"
                        label="Please Enter the AWB Number You can Use Comma(,) to Separate Multiple values"
                        value = {values.awb_number}
                        onChange={handleInputChange}
                        error = {errors.awb_number}
                    />
                     <Input
                        name="piece"
                        label="Please Enter the piece You can Use Comma(,) to Separate Multiple values"
                        value = {values.piece}
                        onChange={handleInputChange}
                        error = {errors.piece}
                    />
                    <Input
                    name="weight"
                    label="Please Enter weight  You can Use Comma(,) to Separate Multiple values"
                    value ={values.weight}
                    onChange={handleInputChange}
                    error ={errors.weight}
                    />
                       <Input
                        name="remark"
                        label="Please Enter the Remark You can Use Comma(,) to Separate Multiple values"
                        value = {values.remark}
                        onChange={handleInputChange}
                        error = {errors.remark}
                    />
                        <Box>
                        <div style={{float:"center"}}>
                      <span style={{color:"red",marginRight:70,marginLeft:150}}>
                      <Button
                            type="submit"
                            variant="contained" color="success"
                            text="Create Items" />
                      </span>
                    
                     <Button
                            text="Reset"
                            variant="contained" color="error"
                            onClick ={resetForm}
                             />
                      </div>
                             {message == "" ? " " :
                             <Stack sx={{ width: '100%' }} spacing={2}>
                                {message == "Item Added Suscessfullly" ?  <Alert severity="success">{message}</Alert> :
                            
                             <Alert severity="error">{message}</Alert>
}
                             </Stack>
                             }
                            </Box>     
                </Grid>
                
            </Grid>
        </Form>
    )
}