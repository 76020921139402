import React from 'react';
import { Box} from "@mui/system";
import { useEffect, useState } from "react";
import AuthService from "../../api/auth/auth.service";
import AdminDashboard from "./admin";
import EmployeeDashboard from "./employee";
import Login from "../authentication/Login";

export default function Dashboard(){
    const [showEmployeeBoard, setShowEmployeeBoard] = useState(false);
    const [showAdminBoard, setShowAdminBoard] = useState(false);
    const [currentUser, setCurrentUser] = useState(undefined);

useEffect(() => {
        const user = AuthService.getCurrentUser();
        if (user) {
          setCurrentUser(user);
          setShowEmployeeBoard(user.roles.includes("ROLE_AGENT"));
          setShowAdminBoard(user.roles.includes("ROLE_ADMIN"));
        }
      }, []);
     // let navigate = useNavigate();
    return (

        <Box>
            
            
        {currentUser ? (<Box>
            {showAdminBoard && (
<AdminDashboard/>
)}
{showEmployeeBoard && (
<EmployeeDashboard/>
)}
</Box>):(<Box>
           {
              <Login/>
           }
        </Box>)}
        
               </Box>

    )          
}