import React from 'react';
import { Grid, Typography } from '@mui/material'
import Button from '../../controls/Button'
import Input from '../../controls/Input'
import { Form, useForm } from '../../controls/useForm'
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import AssignmentQuery from '../../api/assignment/assignment.service';
import AuthService from '../../api/auth/auth.service';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns"; // import
import moment from "moment";
import Checkbox from '@mui/material/Checkbox';
import { pink } from '@mui/material/colors';
import { useState } from 'react';
export default function EditAssignmentForm(props) {
    const { addOrEdit, recordForEdit } = props
    const {saveAssignment,message,setMessag,updateAssignment,assignmentUpdateMessage} = AssignmentQuery();
    const currentUser = AuthService.getCurrentUser();
    const initialFValues = {
        id: props.id,
        AssignmentName: props.AssignmentName,
        routing:props.routing,
        fltType:props.fltType,
        fltDate:props.fltDate,
        wight:props.wight,
        bchName:props.bchName,

    }
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const [selectedDate, setDate] = React.useState(moment());
  const [inputValue, setInputValue] = useState(props.fltNoDate);

  const onDateChange = (date, value) => {
    setDate(date);
    setInputValue(value);
    console.log("kaba" + value)
  };
  const dateFormatter = str => {
    return str;
  };
    const validate = (fieldValues = values) => {
        let temp = { ...errors }
            if ('name' in fieldValues)
            temp.name = fieldValues.name ? "" : "This field is required."
        setErrors({
            ...temp
        })
       
        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    }
    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialFValues, true, validate);
    const handleSubmit = e => {
        e.preventDefault()
        if (validate()) {
            updateAssignment(values,inputValue);
        console.log(values);
         resetForm();
        
        }
    }

  
return (
        <Form onSubmit={handleSubmit}>
            <Grid container>
                <Grid item xs={12}>
                FLT No/Date
                <Typography  style={{ marginLeft: `10px`,color:'black'}}>

    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
         autoOk={true}
         value={selectedDate}
         format="yyyy-mm-dd"
         inputValue={inputValue}
         onChange={onDateChange}
         rifmFormatter={dateFormatter}
            />
    </MuiPickersUtilsProvider>
    </Typography>
                    <Input
                        name="AssignmentName"
                        label="Please Enter the AssignmentName"
                        value = {values.AssignmentName}
                        onChange={handleInputChange}
                        error = {errors.AssignmentName}
                    />
                    <Typography>
                    <Typography>FLT Type</Typography>
                    Freighter      {values.fltType ==="Freighter" ? <Checkbox {...label}   name="fltType" value = "Freighter" label="Label"   defaultChecked  onChange={handleInputChange}/>:
                    <Checkbox {...label}   name="fltType" value = "Freighter" label="Label"     onChange={handleInputChange}/>
                    }      
      Pax {values.fltType ==="Preighier" ? <Checkbox
         {...label}
         sx={{
           color: pink[800],
           '&.Mui-checked': {
             color: pink[600],
           },
         }}
         name="fltType" value = "Pax"
         onChange={handleInputChange}
         defaultChecked
       /> : <Checkbox
         {...label}
         sx={{
           color: pink[800],
           '&.Mui-checked': {
             color: pink[600],
           },
         }}
         name="fltType" value = "Preighier"
         onChange={handleInputChange}
         
       />} 

       </Typography>
                     <Input
                        name="routing"
                        label="Please Enter the routing"
                        value = {values.routing}
                        onChange={handleInputChange}
                        error = {errors.routing}
                    />
                     <Input
                        name="wight"
                        label="Please Enter the Wight"
                        value = {values.wight}
                        onChange={handleInputChange}
                        error = {errors.wight}
                    />
                        <Input
                        name="bchName"
                        label="Please Enter the bchName"
                        value = {values.bchName}
                        onChange={handleInputChange}
                        error = {errors.bchName}
                    />
                  
                             <div style={{float:"center"}}>
                      <span style={{color:"red",marginRight:70,marginLeft:130}}>
                      <Button
                       variant="contained" color="success"
                            type="submit"
                            text="Update Assignment" />
                      </span>
                
                     <Button
                            text="Reset"
                            variant="contained" color="error"
                            onClick ={resetForm}
                             />
                      </div>
                             {assignmentUpdateMessage == "" ? " " :
                             <Stack sx={{ width: '100%' }} spacing={2}>
                                {assignmentUpdateMessage == "userupdated Sucess" ?  <Alert severity="error">{assignmentUpdateMessage}</Alert> :
                            
                             <Alert severity="success">{assignmentUpdateMessage}</Alert>
}
                             </Stack>
                             }
                             
                </Grid>
            </Grid>
        </Form>
    )
}