import axios from "axios";
import baseURL from "../url";
const API_URL = baseURL + '/changePassword';
const changePassword =  (body) => {
    console.log(body);
     const data  =  axios.put('http://localhost:9090/api/changePassword',body);
            return data;
    };
const Setting ={
    changePassword,
}
export default Setting;
