import * as React from 'react';
import { useState } from 'react';
import { Box,Avatar, Card, CardContent, Grid, Typography, Container } from '@mui/material';
import QueueIcon from '@material-ui/icons/Queue';

import { useLocation, useNavigate } from 'react-router-dom';
import AssignmentQuery from '../../../api/assignment/assignment.service';

import {
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns"; // import
import UserQuery from '../../../api/user/user.service';

export default function UserAssignment(props) {
  const {getByCreatedDate,searchBycreatedDate,allAssignmentByDate,userAssignmentList,getUserAssignment} = AssignmentQuery();
  const {getUserById,userData}= UserQuery();
  const location = useLocation();
  const [searchdata,setSearchData] = useState("")
  const [openPopup, setOpenPopup] = useState(false);
  const userId =  location.state.id;
  getUserById(userId)
  const searchByDate = (createdDate) =>{
    searchBycreatedDate(createdDate);
  }
  const [selectedDate, setDate] = React.useState(moment());
  const [inputValue, setInputValue] = useState(moment().format("YYYY-MM-DD"));

  const onDateChange = (date, value) => {
    setDate(date);
    setInputValue(value);
    searchBycreatedDate(value);
  };

  const dateFormatter = str => {
    return str;
  };
const data = {
  userId:userId,
  createdDate:inputValue
}

  const dateNuw =  
 searchBycreatedDate(inputValue);
 getUserAssignment(data)
  const navigate = useNavigate();
  console.log(props)

  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  const event = new Date(Date.UTC(inputValue));
  const sams = event.toLocaleDateString('eng', options);


  return (
    <div>
    <div>
    </div>
    <Card  style={{ margin: `30px`,display: `flex`, alignContent:`center`, justifyContent: `center` }}>
    <h1>List Of All Assignment Created By Agent <span style={{ color : 'green' }}>{userData}</span> Date  <span style={{color:'green'}}>{inputValue}</span></h1>
    <Typography  style={{ marginLeft: `50px`,color:'red'}}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker 
         autoOk={true}
         value={selectedDate}
         format="yyyy-mm-dd"
         inputValue={inputValue}
         onChange={onDateChange}
         rifmFormatter={dateFormatter}
            />
    </MuiPickersUtilsProvider>
    </Typography>
      </Card>
    
          <div style={{ margin: `30px`, display: `flex`, flexDirection: `row`, justifyContent: `center` }}>
          {
            userAssignmentList.map((row)=>  <div className="e-card e-card-horizontal" >
               <Card 
             onClick  = {()=> navigate('/assignment',{state:{id:row.id}})}>
               <CardContent>
    <Grid
            container
            spacing={3}
            sx={{ justifyContent: 'space-between' }}
          >
              <Grid item>
    <Typography
           color="textSecondary"
           gutterBottom
           variant="overline"
         >
          Assignment
   </Typography>
   <Typography
           color="textPrimary"
           variant="h5"
         >
            {row.AssignmentName}
         </Typography>
    </Grid>
    <Grid item>
       <Avatar
         sx={{
           backgroundColor: 'green',
           height: 40,
           width: 40
         }}
       >
         <QueueIcon/>
       </Avatar>
                </Grid>
                </Grid>
                <Box
            sx={{
              pt: 2,
              display: 'flex',
              alignItems: 'center'
            }}
          >
          </Box>
        CreatedBy  {userData}
      </CardContent>
               </Card>
            </div>)
          }
          </div>

          
    </div>
  
  )
}