import * as React from 'react';
import { useState } from 'react';
import { Box,Avatar, Card, CardContent, Grid, Typography, Container, Button } from '@mui/material';
import QueueIcon from '@material-ui/icons/Queue';

import { useNavigate } from 'react-router-dom';
import AssignmentQuery from '../../../api/assignment/assignment.service';

import {
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns"; 
import { Helmet } from 'react-helmet';
export default function SearchByDate(props) {
  const {getByCreatedDate,searchBycreatedDate,allAssignmentByDate,searchBycreatedDateAll} = AssignmentQuery();
  const [searchdata,setSearchData] = useState("")
  const [openPopup, setOpenPopup] = useState(false);
  const searchByDate = (createdDate) =>{
    searchBycreatedDate(createdDate);
    searchBycreatedDateAll(createdDate)
  }
  const [selectedDate, setDate] = React.useState(moment());
  const [inputValue, setInputValue] = useState(moment().format("YYYY-MM-DD"));
  const onDateChange = (date, value) => {
    setDate(date);
    setInputValue(value);
    searchBycreatedDate(value);
    searchBycreatedDateAll(value)
  };
  const dateFormatter = str => {
    return str;
  };
 searchBycreatedDate(inputValue);
 searchBycreatedDateAll(inputValue);
  const navigate = useNavigate();
  console.log(props)
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  const event = new Date(Date.UTC(inputValue));
  const sams = event.toLocaleDateString('eng', options);
  return (
    <div>
    <div>
    </div>
    <Helmet>
          <title>Loadsheet</title>
        </Helmet>
    <Card  style={{ margin: `30px`,display: `flex`, alignContent:`center`, justifyContent: `center` }}>
    <h1>List Of  Assignment Created By  Agents Date <span style={{color:'green'}}>{inputValue}</span></h1>
    <Typography  style={{ marginLeft: `50px`,color:'red'}}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker 
         autoOk={true}
         value={selectedDate}
         format="yyyy-mm-dd"
         inputValue={inputValue}
         onChange={onDateChange}
         rifmFormatter={dateFormatter}
            />
    </MuiPickersUtilsProvider>
    </Typography>
      </Card>
          <div style={{ margin: `30px`, display: `flex`, flexDirection: `row`, justifyContent: `center` }}>
          {
            allAssignmentByDate.map((row)=>  <div style={{ margin: `30px`, display: `flex`, flexDirection: `row`, justifyContent: `center` }}>     
          <Card 
          onClick  = {()=> navigate('/userAssignment',{state:{id:row.userId}})}>
            <CardContent>
         <Grid
         container
         spacing={3}
         sx={{ justifyContent: 'space-between' }}
       >
  <Grid item>
 <Typography
        color="textSecondary"
        gutterBottom
        variant="overline"
      >
       Assignment  <h1>Total {row.count} </h1>
</Typography>
<Typography
        color="textPrimary"
        variant="h5"
      >
      {row.AssignmentName}
      </Typography>
 </Grid>
 <Grid item>
    <Avatar
      sx={{
        backgroundColor: 'green',
        height: 38,
        width: 38
      }}
    >
      <QueueIcon/>
    </Avatar>
             </Grid>
             </Grid>
             <Box
         sx={{
           pt: 2,
           display: 'flex',
           alignItems: 'center'
         }}
       >
       </Box>
       CreatedBy  {row.user.firstName}
   
   </CardContent>
            </Card>
         
            </div>)
          }
          </div>      
    </div> 
  )
}