import React from 'react';
import { Grid, TextField } from '@mui/material'
import Button from '../../controls/Button'
import Input from '../../controls/Input'
import { Form, useForm } from '../../controls/useForm'
import { pink } from '@mui/material/colors';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import AssignmentQuery from '../../api/assignment/assignment.service';
import { CheckBox, TextFields } from '@mui/icons-material';
import Select from '../../controls/Select';
import Checkbox from '@mui/material/Checkbox';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
export default function CheckListForm(props) {
  //  const { addOrEdit, recordForEdit } = props
  console.log("kaba")
  console.log(props);
  const id = props.data[0];
  const oldStatus =props.data[2];
  const checkName = props.data[1];
    const {checklistDetail,updateCheackList,message,setMessag} = AssignmentQuery();
    const initialFValues = {
        id : id,
        status: oldStatus,
    }
    const states = [
        {
          value: 'one',
          label: 'one'
        },
        {
          value: 'Two',
          label: 'Two'
        },
        {
          value: 'Three',
          label: 'Three'
        }
      ];
   console.log("samisams enewar")
console.log(props.id);
console.log(props.name);
console.log(props.status);
    const validate = (fieldValues = values) => {
        let temp = { ...errors }
            if ('status' in fieldValues)
            temp.status = fieldValues.status ? "" : "This field is required."
        setErrors({
            ...temp
        })
       
        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    }
    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialFValues, true, validate);
    const handleSubmit = e => {
        e.preventDefault()
        if (validate()) {
            updateCheackList(values);
        console.log(values);
         resetForm();
        }
    }

  
return (
        <Form onSubmit={handleSubmit}>
            <Grid container>
                <Grid item xs={12} md={12} xl={12}>
                {checkName}
                   
               
            <div>
         YES   <Checkbox {...label}   name="status" value = "YES" label="Label"   onChange={handleInputChange}/>
    
   NO   <Checkbox
        {...label}
        sx={{
          color: pink[800],
          '&.Mui-checked': {
            color: pink[600],
          },
        }}
        name="status" value = "NO"
        onChange={handleInputChange}
      />
     N/A   <Checkbox  name="status" value = "N/A" {...label}  color="default"    onChange={handleInputChange}/>
            </div>

                     <Grid>
                     <Button
                            type="submit"
                            text="Submit" />
                     <Button
                            text="Reset"
                            onClick ={resetForm}
                             />
                     </Grid>
                   
                             {message == "" ? " " :
                             <Stack sx={{ width: '100%' }} spacing={2}>
                                {message == "Service Already Exist" ?  <Alert severity="error">{message}</Alert> :
                            
                             <Alert severity="success">{message}</Alert>
}
                             </Stack>
                             }
                             
                </Grid>
            </Grid>
        </Form>
    )
}