import { Box, Card, Container, Grid, Typography } from "@mui/material";
import MUIDataTable from "mui-datatables";
import React from 'react';
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import AssignmentQuery from "../../api/assignment/assignment.service";
import AuthService from "../../api/auth/auth.service";
import ItemQuery from "../../api/item/itemService";
import Button from '../../controls/Button';
import Popup from "../../controls/Popup";
import CheackList from "../checkList/cheackLists";
import CheckListForm from "../checkList/checkListForm";
import { ItemListToolbar } from "../item/item-list-toolbar";
import ItemEditForm from "../item/itemEditForm";
import { ExcelExport, Inject, Toolbar } from '@syncfusion/ej2-react-grids';
import { ColumnDirective, ColumnsDirective, GridComponent } from '@syncfusion/ej2-react-grids';
import Checkbox from '@mui/material/Checkbox';
import { pink } from '@mui/material/colors';
import { TableFooter, TableCell, TableRow } from "@material-ui/core";
import { useEffect } from "react";
import UserQuery from "../../api/user/user.service";
import moment from "moment/moment";

export default function AssignmentDetail(){
  //const currentDate = new Date();
  const currentDate= moment().format("YYYY-MM-DD");
  console.log(currentDate +  "samisams");
  const currentUser = AuthService.getCurrentUser();
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  let navigate = useNavigate();
  const location = useLocation();
  const {AssignmentList,checklistDetail,getAssignmentById,getAllCheckListAsseignmentById,AssignmentById,specificAssignmet} = AssignmentQuery();
  const {getUserById,userById} = UserQuery();
  const {itemList} = ItemQuery();
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupCheakList,setOpenPopupCheackList] = useState(false);
  const [openEditItem,setOpenEditItem] = useState(false);
  const [newData,setNewData] =useState("")
  console.log(openPopup);
  const id = location.state.id;
  


  //const fullName = specificAssignmet.user.map((country) => {return  country.firstName})

  getUserById(2);
  console.log(userById + "kingu")
  getAssignmentById(location.state.id);
  getAllCheckListAsseignmentById(location.state.id)
  AssignmentById(id);
    React.useEffect(() => {
    }, [location]);
  if(currentUser){ 
    return (
      <Box>
        <Helmet>
          <title>Loadsheet</title>
        </Helmet>
        {
          currentUser.roles == "ROLE_AGENT"  && specificAssignmet.createdDate === currentDate  ?   <ItemListToolbar id= {location.state.id}/> : 
          <Card>
            <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        m: -1
      }}
    >
      <Typography
        sx={{ m: 1 }}
        variant="h4"
      >
      </Typography>
      
    </Box>
           
          </Card>
          
        }
    
      <Box sx={{ m: 4 }}></Box>
      <Grid container spacing={4}>
        <Grid item xs={12}>
        <MUIDataTable
     
         options={{// <===== will turn off checkboxes in rows
          filter: true,
          filterType: "dropdown",
          responsive: "standard",
          selectableRows: false, 
          rowsPerPage: AssignmentList.length,
      customFooter: (
            count=20,
            page,
            rowsPerPage,
            changeRowsPerPage,
            changePage
          ) => {
            return (
              <div> 
                <h5 style={{fontSize:20,color:"black"}}>Total Wight: {" " +specificAssignmet.wight  + " "+ "Kg" }</h5>
             <div  style={{topPadding:200,marginTop:30}}>
              {'\n'}
              <div><span style={{fontSize:20,color:"black"}}>BCH Name/ID:  {specificAssignmet.bchName}</span> <span style={{fontSize:20,color:"black",float:"right"}}>Signature:________________________</span> </div>
              <div><span  style={{fontSize:20,}}>Loading Agent Name/ID:  {specificAssignmet.userId + "  " + " "+ " " +currentUser.lastName} </span><span style={{fontSize:20,color:"black",float:"right"}}> Signature:______________________________</span></div>
             </div>
             </div>
            );
          },   
        }}    
          title={
            <div>
              <img src={require('../../layoutes/logo.svg')} /> <h2 style={{color: "black",float:"center",textAlign:"center"}}>ETHIOPIAN CARGO TRANSIT HANDLING 
              LOAD PREPARATION SHEET
              </h2>
              <div> 
        FLT Date: {specificAssignmet.fltNoDate} 
        <span style={{fontSize:20,float:"right",}}>Loadsheet Preparation Date:  {specificAssignmet.createdDate} </span> </div>
       <div>
       <Typography>
       FLT Type  : {specificAssignmet.fltType}   <Checkbox {...label}   name={specificAssignmet.fltType} value = {specificAssignmet.fltType}
       {...label}
       sx={{
         color: pink[800],
         '&.Mui-checked': {
           color: pink[600],
         },
       }}
       disabled checked
      />
      <span style={{fontSize:20,float:"right"}}> Routing:  {specificAssignmet.routing} </span>
      
       </Typography>
      </div>
            </div>
          }
          data={AssignmentList}
          
          columns={["id","SN","Pallet number","Awb number","Piece", "Weight", "REMARK", {
            name: "Cheack List",
            options: {
              filter: true,
              sort: false,
              empty: true,
              customBodyRender: (value, tableMeta, updateValue) => {
                const id2 = tableMeta.rowData[0];
                return (
                
                     <Button
                     text={"Cheack List"}
                     variant="contained" color="success"
                     onClick={() => { setOpenPopupCheackList(true);setNewData(id2);}}
                 />
                );
              }
            }
          },
         currentUser.roles == "ROLE_ADMIN" ?  {
          name: "Edit",
          options: {
            filter: true,
            sort: false,
            empty: true,
            customBodyRender: (value, tableMeta, updateValue) => {
              const id2 = tableMeta.rowData[0];
              return (
              
                   <Button
                   text={"Edit"}
                   variant="contained" 
                 //  onClick={() => { setOpenEditItem(true);setNewData(tableMeta.rowData);}}
                 disabled
               />
               

              );
            }
          }
        }:(specificAssignmet.createdDate === currentDate? {
          name: "Edit",
          options: {
            filter: true,
            sort: false,
            empty: true,
            customBodyRender: (value, tableMeta, updateValue) => {
              const id2 = tableMeta.rowData[0];
              return (
              
                   <Button
                   text={"Edit"}
                   variant="contained" color="primary"
                   onClick={() => { setOpenEditItem(true);setNewData(tableMeta.rowData);}}
               />

              );
            }
          }
        }:
        {
          name: "Edit",
          options: {
            filter: true,
            sort: false,
            empty: true,
            customBodyRender: (value, tableMeta, updateValue) => {
              const id2 = tableMeta.rowData[0];
              return (
              
                   <Button
                   text={"Edit"}
                   variant="contained" color="primary"
                  // onClick={() => { setOpenEditItem(true);setNewData(tableMeta.rowData);}}
                  disabled
               />

              );
            }
          }
        }
        )
         
         ]}
        />
       
      </Grid>
      <Grid item xs={12}>
      </Grid>

   
  
    </Grid>
 <Popup  
                title="Edit Cheak List"
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                
            >
               <CheckListForm id = {newData[0]} name ={newData[1]} status={newData[2]}/>
 </Popup>
 <Popup 
     title="Attachements"
     openPopup={openPopupCheakList}
     setOpenPopup={setOpenPopupCheackList}>
  <CheackList id = {newData}/>
 </Popup>

 <Popup 
     title="Edit Item"
        openPopup={openEditItem}
     setOpenPopup={setOpenEditItem}>
      <ItemEditForm id = {newData[0]} sn ={newData[1]}  pallet_number={newData[2]} awb_number={newData[3]} piece={newData[4]}  wieght = {newData[5]} remark={newData[6]}/>
 </Popup>
 </Box>
      );}else{
          navigate("/");
          window.location.reload();  
        }     
}