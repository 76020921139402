import * as React from 'react';
import { useState } from 'react';
import { Box,Avatar, Card, CardContent, Grid, Typography, Container, Button } from '@mui/material';
import QueueIcon from '@material-ui/icons/Queue';

import { useNavigate } from 'react-router-dom';
import AssignmentQuery from '../../../api/assignment/assignment.service';

import {
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
  KeyboardDatePicker,

  DatePicker
} from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns"; // import
import AuthService from '../../../api/auth/auth.service';
import Popup from '../../../controls/Popup';
import EditAssignmentForm from '../../assignment/edit-assignment-form';
import { Helmet } from 'react-helmet';
import { AssignmentListToolbar } from '../../assignment/assignment-list-toolbar';

export default function Home(props) {
 const currentDate = moment().format("YYYY-MM-DD");
  const {searchBycreatedDate,allAssignmentByDate,getByCreatedDate} = AssignmentQuery();
  const currentuser = AuthService.getCurrentUser();
  const [openPopup, setOpenPopup] = useState(false);
  const[newData,setNewData] = useState("");
  const searchByDate = (createdDate) =>{
    searchBycreatedDate(createdDate);
  }
  const [selectedDate, setDate] = React.useState(moment());
  const [inputValue, setInputValue] = useState(moment().format("YYYY-MM-DD"));

  const onDateChange = (date, value) => {
    setDate(date);
    setInputValue(value);
    searchBycreatedDate(value);
    console.log("kaba" + value)
  };

  const dateFormatter = str => {
    return str;
  };


  const dateNuw =  
 searchBycreatedDate(inputValue);
  const navigate = useNavigate();
  console.log(props)

  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  const event = new Date(Date.UTC(inputValue));
  const sams = event.toLocaleDateString('eng', options);

const currentUser = AuthService.getCurrentUser();

const loadRefreshContent=()=> {
  window.location.reload(false);
}
  return (
    <div>
      {
        currentDate === inputValue ? <AssignmentListToolbar/>:''
      }
    
    <Card  style={{ margin: `30px`,display: `flex`, alignContent:`center`, justifyContent: `center` }}>
    <h1>List Of All Assignment Created By Date <span style={{color:'green'}}>{inputValue}</span></h1>
    <Typography  style={{ marginLeft: `50px`,color:'red'}}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
    
    <KeyboardDatePicker
     autoOk={true}
    variant="inline"
    format= "yyyy/MM/dd"
    margin="normal"
    name="startDate"
    value={selectedDate}
    onChange={onDateChange}
    KeyboardButtonProps={{
     "aria-label": "change date"
    }}
   />
            
         
    </MuiPickersUtilsProvider>
    </Typography>
      </Card>
          <div style={{ margin: `30px`, display: `flex`, flexDirection: `row`, justifyContent: `center` }}>
          {
            getByCreatedDate.map((row)=>  <div className="e-card e-card-horizontal" >
              
               <Card 
           >
               <CardContent   onClick  = {()=> {navigate('/assignment',{state:{id:row.id}});loadRefreshContent()}}>
    <Grid
            container
            spacing={1}
            sx={{ justifyContent: 'space-between' }}
          >
              <Grid item>
    <Typography
           color="textSecondary"
           gutterBottom
           variant="overline"
         >
          Assignment
   </Typography>
   <Typography
           color="textPrimary"
           variant="h5"
         >
            {row.AssignmentName}
         </Typography>
    </Grid>
    <Grid item>
       <Avatar
         sx={{
           backgroundColor: 'green',
           height: 40,
           width: 40
         }}
       >
         <QueueIcon/>
       </Avatar>
                </Grid>
                </Grid>
                <Box
            sx={{
              pt: 2,
              display: 'flex',
              alignItems: 'center'
            }}
          >
          </Box>
       
      </CardContent>
      CreatedBy  {currentuser.firstName} 
      {
        currentDate === inputValue ?    <Button
        variant="outlined"
        style={{ textTransform: "none", padding: "5px 0px" }} //button Size change in React Material Ui
        onClick={() => {setOpenPopup(true);setNewData(row)}}
        >Edit </Button>:''
      }
     

               </Card>
              
            </div>
            )
          }
          </div>
          <Popup  
                title="Edit Assignment"
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                
            >
              <EditAssignmentForm AssignmentName = {newData.AssignmentName} id = {newData.id} routing={newData.routing}  fltType={newData.fltType} fltNoDate={newData.fltNoDate} wight = {newData.wight} bchName={newData.bchName}/>
 </Popup>
 </div>
  )
}