import { Box, Container } from "@mui/material";
import React from 'react';
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import AuthService from "../../api/auth/auth.service";
import AssignmentDetail from "../../components/assignment/assignmentDetail";
  export default function Item(){
    let navigate = useNavigate();
    const currentUser = AuthService.getCurrentUser();
    if(currentUser){ 
      return (
          <>
           <Helmet>
        <title>Loadsheet</title>
  </Helmet>
  <AssignmentDetail/>
        </>
        );}else{
            navigate("/");
            window.location.reload();  
          }
        }    