import React from 'react';
import { Grid } from '@mui/material'
import { useEffect } from 'react'
import Controls from '../../controls/Controls'
import { Form, useForm } from '../../controls/useForm'
import UserQuery from '../../api/user/user.service';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import ItemQuery from '../../api/item/itemService';
import { useNavigate } from 'react-router-dom';
export default function ItemEditForm(props) {
    const { addOrEdit, recordForEdit } = props
    //const {saveUser,message,setMessage} = UserQuery();
    const {updateItem,message,setMessage} = ItemQuery();
    const navigate = useNavigate();
    const initialFValues = {
        id: props.id,
        sn: props.sn,
        awb_number: props.awb_number,
        piece: props.piece,
        pallet_number: props.pallet_number,
        remark: props.remark,
        wieght:props.wieght
    }
    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        
    
          // setMessage("")
        setErrors({
            ...temp
        })

        if (fieldValues == values)
            return Object.values(temp).every(x => x == "")
            
    }
    const {
        values,
        setValues,
        errors,
        setErrors,
         handleInputChange,
        resetForm
    } = useForm(initialFValues, true, validate);
    const handleSubmit = e => {
        e.preventDefault()
        if (validate()) {
        updateItem(values)
        console.log(values);
         resetForm();
        }
    }
    useEffect(() => {
        if (recordForEdit != null)
            setValues({
                ...recordForEdit
            })
    }, [recordForEdit])
  
return (
    <Form onSubmit={handleSubmit}>
    <Grid container>

        <Grid item xs={6}>

              <Controls.Input
                label="sn"
                name="sn"
                value={values.sn}
                onChange={handleInputChange}
                error={errors.sn}
            />
           <Controls.Input
                name="pallet_number"
                label="pallet_number"
                value={values.pallet_number}
                onChange={handleInputChange}
                error={errors.pallet_number}
            />
               <Controls.Input
                label="awb_number"
                name="awb_number"
                value={values.awb_number}
                onChange={handleInputChange}
                error={errors.awb_number}
            />

        </Grid>

        <Grid item xs={6}>
           

            <div>
            <Controls.Input
                label="piece"
                name="piece"
                value={values.piece}
                onChange={handleInputChange}
                error={errors.piece}
            />
             <Controls.Input
                label="	wieght"
                name="wieght"
                value={values.wieght}
                onChange={handleInputChange}
                 error={errors.wieght}
            />	
             <Controls.Input
                label="	remark"
                name="remark"
                value={values.remark}
                onChange={handleInputChange}
                 error={errors.remark}
            />	
           
                
            </div>
          
        </Grid>
        <div style={{float:"center"}}>
                      <span style={{color:"red",marginRight:70,marginLeft:150}}>
                      <Controls.Button
                    type="submit"
                    variant="contained" color="success"
                    text="Update Item" 
                   // onClick  = {()=> navigate('/assignment')}
                    />
                      </span>
                    
                      <Controls.Button
                    text="Reset"
                    //color="default"
                    variant="contained" color="error"
                    onClick={resetForm} />
                      </div>
        {message == "" ? " " :
                             <Stack sx={{ width: '100%' }} spacing={2}>
                                {message == "User registered successfully!" ?  <Alert severity="success">{message}</Alert>:
                             <Alert severity="error">{message}</Alert> 
                            
}
                             </Stack>
                             }
    </Grid>
</Form>
    )
}