import * as React from 'react';
import { Box, Container, Grid } from '@mui/material';
import Example from '../../components/dashboard/admin/example';
import { AssignmentListToolbar } from '../../components/assignment/assignment-list-toolbar';

export default function AdminDashboard() { 
  return (
    <Box > 
    <Container maxWidth={false}>
      <Grid
        container
        spacing={3}
      >
          <Grid>
           <Example /> 
          </Grid>
          </Grid>
          </Container>
          </Box>
  );
}
