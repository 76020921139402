import * as React  from 'react';
import { useState } from 'react';
import {
  Box,
 Typography
} from '@mui/material';
import ActionButton from '../../controls/ActionButton';
import Popup from '../../controls/Popup';
import Button from '../../controls/Button';
import AssignmentQuery from '../../api/assignment/assignment.service';
import ItemForm from './itemForm';

export const ItemListToolbar =(props)=>{
  const [searchdata,setSearchData] = useState("")
  const [openPopup, setOpenPopup] = useState(false);
  const {searchBycreatedDate}  = AssignmentQuery();
return( 
<Box>
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        m: -1
      }}
    >
      <Typography
        sx={{ m: 1 }}
        variant="h4"
      >
       
      </Typography>
      <Box sx={{ m: 2 }}>
    
      <ActionButton />
        <Button
                        text="create New Items"
                        variant="contained" color="success"
                        onClick={() => { setOpenPopup(true);  }}
                    />
      </Box>
    </Box>
    <Popup
                title="Items Form"
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
            >
            <ItemForm id ={props.id}/>
            </Popup>
  </Box>
);
  

  
    }
