import React from 'react';
import Button from '../../controls/Button'
import Input from '../../controls/Input'
import { Form, useForm } from '../../controls/useForm'
import { Box, Card, CardContent, CardHeader, Divider, Grid, TextField } from '@mui/material';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import SettingQuery from '../../api/setting/setting.service';

export const SettingsPassword = (props) => {
    const {updatePassword,message,setMessag} = SettingQuery();
    const initialFValues = {
        password: '',
        confirm: '',
        id :23
    }
   

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('password' in fieldValues)
            temp.password = fieldValues.password ? "" : "This password is required."
        if ('confirm' in fieldValues){
          temp.confirm = fieldValues.confirm ? "" : "This confirm is required."
        }if(1==1) {
          temp.confirm = fieldValues.confirm ? "" : "Not match."
        }else{
          temp.confirm = fieldValues.confirm ? "" : "This confirm is required."
        }
       
        setErrors({
            ...temp
        })
       // setMessag("")
        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    }
    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialFValues, true, validate);
    const handleSubmit = e => {
        e.preventDefault()
        if (validate()) {
          updatePassword(values);
         resetForm();
        }
    }

  
return (
  <Form onSubmit={handleSubmit}>
  <Card>
    <CardHeader
      subheader="Update password"
      title="Password"
    />
    <Divider />
    <CardContent>
    <Input
                        name="password"
                        label="Please Enter the  New Password "
                        value = {values.password}
                        onChange={handleInputChange}
                        error = {errors.password}
                    />
       <Input
                        name="confirm"
                        label="Please Enter the  confirm Password "
                        value = {values.confirm}
                        onChange={handleInputChange}
                        error = {errors.confirm}
                    />
    </CardContent>
    <Divider />
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        p: 2
      }}
    >
    
                 
                        
    </Box>
    <Grid> 
       <Button
                            type="submit"
                            text="Submit" />
        <Button
                            text="Reset"
                            onClick ={resetForm} />
     </Grid>
 
   {message == "" ? " " :
                             <Stack sx={{ width: '100%' }} spacing={2}>
{message == "Pass was updated successfully" ?  <Alert severity="error">{message}</Alert> :<Alert severity="success">{message}</Alert>
}
                             </Stack>
                             }
  </Card>
</Form>
    )
}