import axios from "axios";
import { useEffect, useState } from "react";
import AuthService from "../auth/auth.service";
import baseURL from "../url";
import Assignment from "./assignment";

const AssignmentQuery = () => {
  const currentuser = AuthService.getCurrentUser();
  const [allAssignment, setAllAssignment] = useState([]);
  const [assignmentDetail, setAssignmentDetail] = useState([]);
  const [assignment, setAssignment] = useState([]);
  const [getByCreatedDate,setGethByCreatedDate] = useState([]);
  const [allAssignmentByDate,setAllAssignmentByDate] = useState([]);
  const [message,setMessag] = useState("");
  const [userAssignmentList,setUserAssignment] = useState([]);
  const [specificAssignmet,setSpecificAssignmet] =useState([]);
  const [assignmentUpdateMessage,setAssignmentUpdateMessage] = useState("");
 const getAssignment =  (data) => {
   Assignment.getAllAssignment()
     .then((response) => {
      setAllAssignment(response.data);
       console.log(response.data);
     })
     .catch((e) => {
       console.log(e);
     });
 };
 const AssignmentById =  (id) => {
   Assignment.AssignmentById(id)
     .then((response) => {
       setSpecificAssignmet(response.data);
       console.log(response.data);
       console.log("bitsu")
     })
     .catch((e) => {
       console.log(e);
     });
};
 const getAssignmentById =  (data) => {
       const  id=data;
        Assignment.getAllAssignmentById(id)
          .then((response) => {
            setAssignmentDetail(response.data);
            console.log(response.data);
            console.log("mami")
          })
          .catch((e) => {
            console.log(e);
          });
  };
    const getAllCheckListAsseignmentById =  (data) => {
        const  id=data;
         Assignment.getAllCheckListAsseignmentById(id)
           .then((response) => {
             setAssignment(response.data);
             console.log(response.data);
             console.log("mami")
           })
           .catch((e) => {
             console.log(e);
           });
       };
      useEffect(()=>{
        getAssignmentById();
        getAssignment();
        getAllCheckListAsseignmentById();
        searchBycreatedDateAll();
        getUserAssignment();
        AssignmentById();
      },[])
      const saveAssignment = async (data,input) =>{
        
        const userId = data.userId;
        const name = data.name;
        const routing =data.routing;
        const fltNoDate = input;
        const fltType =  data.fltType;
        const bchName =  data.bchName;
        try {
          await axios.post(baseURL + '/api/saveAssignment', {
            userId,name,routing,fltNoDate,fltType,bchName
          }
          );
        //  Service.saveService();
        } catch (error) {
          console.log(error.response);
         setMessag(error.response.message)
          // Check if it's HTTP 400  error
          if (error.response.status === 400) {
            console.log(`HTTP 400 error occured`);

          }
          // You can get response data (mostly the reason would be in it)
          if (error.response.data) {
            console.log(error.response.data);
            setMessag(error.response.data.message)

          }
          // throw error if you want to handle it somewhere
          // throw error;
        }

      }
      const updateCheackList = (body) => {
        const id = 1;
        Assignment.updateCheckList(body) .then((response) => {
            console.log(response.data);
        //    setUpdatePassword("The Password was updated successfully!");
          })
          .catch((e) => {
            console.log(e);
          }); 
      };
      const updateAssignment = (body,inputValue) => {
        Assignment.updateAssignment(body,inputValue) .then((response) => {
            console.log(body);
            setAssignmentUpdateMessage("Assignment updated successfully!");
          })
          .catch((e) => {
            console.log(e);
          }); 
      };
      const searchBycreatedDate =  (createdDate) => {
        console.log(createdDate);
         Assignment.getAssignmentByDate(createdDate)
           .then((response) => {
            setGethByCreatedDate(response.data);
             console.log(response.data);
           })
           .catch((e) => {
             console.log(e);
           });
       };
const searchBycreatedDateAll =  (createdDate) => {
       console.log(createdDate);
       console.log("belete");
        Assignment.getAssignmentByDateAll(createdDate)
          .then((response) => {
            setAllAssignmentByDate(response.data);
            console.log(response.data);
          })
          .catch((e) => {
            console.log(e);
          });
  };
const getUserAssignment =  (data) => {
  console.log(data);
  console.log(data + "sami kaba");
   Assignment.userAssignment(data)
     .then((response) => {
      setUserAssignment(response.data);
       console.log(response.data);
       console.log("mick data");
     })
     .catch((e) => {
       console.log(e);
     });
 };
      const AssignmentList = assignmentDetail.map((row) => (
        [row.id,row.sn,row.pallet_number,row.awb_number,row.piece,row.weight,row.remark,row.createdAt]
        ));
     const checklistDetail = assignment.map((row) => (
              [row.id,row.checklist.name,row.status]
            ));
    return {
      AssignmentList,
      assignment,
      checklistDetail,
      allAssignment,
      getAssignment, 
      getAssignmentById,
      saveAssignment,
      updateCheackList,
      getAllCheckListAsseignmentById,
      searchBycreatedDate,
      getByCreatedDate,
      searchBycreatedDateAll,
      allAssignmentByDate,
      message,
      getUserAssignment,
      userAssignmentList,
      specificAssignmet,
      AssignmentById,
      updateAssignment,
      assignmentUpdateMessage
    };
  };
  
  export default AssignmentQuery;