import Iconify from "../components/Iconify";
import React from 'react';
const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

export const EmployeeNavConfig = [
  { 
    title: 'Dashboard',
    path: '/',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'Account',
    path: 'account',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'Setting',
    path: 'setting',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
];
  