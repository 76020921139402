import * as React from 'react';
import { Box,Avatar, Card, CardContent, Grid, Typography, Container } from '@mui/material';
import QueueIcon from '@material-ui/icons/Queue';

import { useNavigate } from 'react-router-dom';
import AssignmentQuery from '../../../api/assignment/assignment.service';
import { AssignmentListToolbar } from '../../assignment/assignment-list-toolbar';
import SearchByDate from './searchByDate';
export default function Example() {
  const {allAssignment,getAssignment} = AssignmentQuery();
//  getAssignment();
  console.log("assignment");
  const navigate = useNavigate();
  return (
    <div>   
          <div>
          <SearchByDate/>
         </div>
    </div>
  
  )
}