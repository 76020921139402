import React from 'react';
import { ListItemText } from '@material-ui/core';
import { List, ListItemButton, ListItemIcon, } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AuthService from '../api/auth/auth.service';
import { EmployeeNavConfig } from './employeeNavConfig';
import { navConfig } from './NavConfig';
export default function NavSection() {
    let navigate = useNavigate();
    const routeChange = (path) =>{ 
        navigate(path);
      }
const currentUser = AuthService.getCurrentUser();
  const lists = navConfig.filter(navItem =>
    navItem 
  );

  const listsEmp = EmployeeNavConfig.filter(navItem =>
    navItem 
  );

  const listItems = lists.map(navItem =>
    <List
    sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
  key={navItem.title}
  >
    <ListItemButton onClick={()=>routeChange(navItem.path)}> 
      <ListItemIcon>
        {navItem.icon} 
      </ListItemIcon>
      <ListItemText>{navItem.title}</ListItemText>
    </ListItemButton>
    
  </List>
  );
  const listItemsEmp = listsEmp.map(navItem =>
    <List
    sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
  key={navItem.title}
  >
    <ListItemButton onClick={()=>routeChange(navItem.path)}> 
      <ListItemIcon>
        {navItem.icon} 
      </ListItemIcon>
      <ListItemText>{navItem.title}</ListItemText>
    </ListItemButton>
    
  </List>
  );
 

  if(currentUser){
    if(currentUser.roles == "ROLE_AGENT"){
        return <ul>{listItemsEmp}</ul>
    }else{
      return <ul>{listItems}</ul>
    }
   
  }

}
