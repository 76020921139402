import axios from "axios";
import { useEffect, useState } from "react";
import baseURL from "../url";
import User from "./user.api";

const UserQuery = () => {
  const [user, setUser] = useState([]);
  const [updateUser, setuUdateUser] = useState([]);
  const [message,setMessage] = useState([]);
  const [countUser,setCountUser] = useState(0);
  const [userById,setUserById] = useState([]);
    const getUsers =  () => {
      User.gtAllUsers()
          .then((response) => {
            setUser(response.data);
            setCountUser(response.data.length);
            console.log(response.data);
          })
          .catch((e) => {
            console.log(e);
          });
      };
      const   getUserById   =  (data) => {
        const  id=data;
        console.log(data);
        console.log("fre");
         User.userById(id)
           .then((response) => {
             setUserById(response.data);
             console.log(response.data);
             console.log("mami")
           })
           .catch((e) => {
             console.log(e);
           });
       };
      const saveUser = async (data) =>{
        const username = data.username
        const email = data.email
        const password = data.password
        const firstName = data.firstName
        const lastName = data.lastName
        const windowNumber = data.windowNumber
        const role = 2
    
        try {
      //    await axios.post('https://queueapi.ethiopiankidneyassociation.com/api/auth/signup', {
        await axios.post(baseURL + '/api/auth/signup', {
            username,
            email,
            password,
            firstName,
            lastName,
            windowNumber,
            role
           });
        //  Service.saveService();
        } catch (error) {
          console.log(error.response);
          setMessage(error.response.message)
          // Check if it's HTTP 400  error
          if (error.response.status === 400) {
            console.log(`HTTP 400 error occured`);

          }
          // You can get response data (mostly the reason would be in it)
          if (error.response.data) {
            console.log(error.response.data);
            setMessage(error.response.data.message)

          }
          // throw error if you want to handle it somewhere
          // throw error;
        }

      }
      const updateUserDetail = (body) => {
        const id = 1;
        User.updateCheckList(body) .then((response) => {
            console.log(response.data);
        //    setUpdatePassword("The Password was updated successfully!");
          })
          .catch((e) => {
            console.log(e);
          }); 
      };
      const updateusers = (body) => {
        console.log(body.role + "bubu")
        User.updateUserDetail(body)
          .then((response) => {
            console.log(response.data);
            setuUdateUser("The Password was updated successfully!");
          })
          .catch((e) => {
            console.log(e);
          });
      };

      useEffect(()=>{
        getUsers();
       
        
      },[])
      userById.map((data)=>{

      })
      const userData = userById.map((row) => (
        [row.firstName]
      ));
    return {user,saveUser,message,setMessage,countUser,updateUser,getUsers,updateusers,getUserById,userData};
  };
  
  export default UserQuery;