import * as React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { SettingsPassword } from '../../components/settings/settings-password';
import AuthService from '../../api/auth/auth.service';
import { useNavigate } from 'react-router-dom';
export default function Settings(){
  const currentuser = AuthService.getCurrentUser();
  let navigate = useNavigate();
  if(currentuser){
    return (
      <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8
      }}
    >
      <Container maxWidth="lg">
        <Typography
          sx={{ mb: 3 }}
          variant="h4"
        >
          Settings
        </Typography>
        SystemName 
        SettingsPrint 
        SettingsNotifications 
        <Box sx={{ pt: 3 }}>
          <SettingsPassword /> 
        </Box>
      </Container>
    </Box>
      );
  }else{
    navigate("/");
    window.location.reload();  
  }
  
}