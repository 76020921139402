import React from "react";
function Child(props) {
return (
<div>
<button onClick={() => props.changeText("world")}>
Change the text
</button>
</div>
);
}
export default Child;