import * as React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import Dashboard from '../pages/dashboard';
import Account from '../pages/account';
import User from '../pages/users';
import Setting from '../pages/setting';
import DashboardLayout from '../layoutes';
import Page404 from '../pages/page404';
import Login from '../pages/authentication/Login';
import Assignment from '../pages/assignment';
import Item from '../pages/item';
import Report from '../pages/report';
import AssignmentUser from '../pages/assignment/userAssignment';
import ReactExample from '../api/ractExample';
import Child from '../api/Child';
export default function Router() {
    return useRoutes([
        {
            path: '/',
            element: <DashboardLayout />,
            children: [
              { path: '/', element: <Dashboard /> },
              
              { path: 'account', element: <Account /> },
              { path: 'users', element: <User /> },
              { path: 'setting', element: <Setting /> },
              { path: 'assignment', element: <Assignment /> },
              { path: 'userAssignment', element: <AssignmentUser /> },
              { path: 'item', element: <Item /> },
              {path:'reactExample',element:<ReactExample />},
              {path:'child',element:<Child />}
            ],
          },
          {
            path: '/',
            children: [
              { path: '/', element: <Navigate to="/dashboard" /> },
              { path: 'login', element: <Login /> },
            ],
          },
          { path: '*', element: <Page404/> },

    ]);
}

