import React from 'react';
import { Avatar, Button, Card, CardActions, CardContent, Divider, Input, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useState } from "react";
import AuthService from '../../api/auth/auth.service';
const currentUser = AuthService.getCurrentUser();

  export const AccountProfile = () => {
      const [file, setFile] = useState();
      const [fileName, setFileName] = useState("");
 
      const saveFile = (e) => {
        setFile(e.target.files[0]);
        setFileName(e.target.files[0].name);
      };
 
      const uploadFile = async (e) => {
        console.log("afe")
        const formData = new FormData();
        formData.append("file", file);
        formData.append("fileName", fileName);
        
        try {
          const res = await axios.put(
            "http://localhost:9090/api/uploadImage",
            {file,fileName}
          );
          console.log(res);
        } catch (ex) {
          console.log(ex);
        }
      };
 

      return (
        <Card>
        <CardContent>
          <Box  
           sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column'
          }}
          >
             {
              currentUser.imgPath ?     <Avatar
              src={require('../../assets/' + currentUser.imgPath  ) } alt="photoURL" 
            sx={{
              height: 200,
              mb: 2,
              width: 200
            }}
          /> :
          <Avatar
          src={require('../../assets/sams.jpg'  ) } alt="photoURL" 
        sx={{
          height: 200,
          mb: 2,
          width: 200
        }}
      />
            }

            
      <Typography
          color="textPrimary"
          gutterBottom
          variant="h5"
        >
    {currentUser.firstName  + " " + currentUser.lastName}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
        >
        </Typography>
        <Typography
          color="textPrimary"
          gutterBottom
          variant="h5"
        ></Typography>
          </Box>
     
        </CardContent>
        <Divider />
        <CardActions>
          <Input 
          type="file"
          onChange={saveFile}
          />
            <br />
            <Button
        color="primary"
        fullWidth
        variant="text"
        onClick={uploadFile}
      >
        Upload picture
      </Button>
    </CardActions>
        </Card>
      );
    
}
 