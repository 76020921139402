import React from 'react';
import { Grid } from '@mui/material'
import { useEffect } from 'react'
import Controls from '../../controls/Controls'
import { Form, useForm } from '../../controls/useForm'
import UserQuery from '../../api/user/user.service';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import { pink } from '@mui/material/colors';
import { green } from '@mui/material/colors';
import Checkbox from '@mui/material/Checkbox';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
export default function UserEditForm(props) {
    const { addOrEdit, recordForEdit } = props
    const {updateUser,message,setMessage,updateusers} = UserQuery();
    const initialFValues = {
        userId: props.id,
        roleId: props.role,
        status:props.status,
    }
 

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('role' in fieldValues)
            temp.role = fieldValues.role ? "" : "This field is required."
        setMessage("")
        setErrors({
            ...temp
        })

        if (fieldValues == values)
            return Object.values(temp).every(x => x == "")
            
    }
    const {
        values,
        setValues,
        errors,
        setErrors,
         handleInputChange,
        resetForm
    } = useForm(initialFValues, true, validate);
    const handleSubmit = e => {
        e.preventDefault()
        if (validate()) {
            updateusers(values)
        console.log(values);
         resetForm();
        }
    }
    useEffect(() => {
        if (recordForEdit != null)
            setValues({
                ...recordForEdit
            })
    }, [recordForEdit])
  
return (
    <Form onSubmit={handleSubmit}>
    <Grid container>
        <Grid item xs={6}>
             <div> 
     Agent   <Checkbox
        {...label}
        sx={{
          color: green[800],
          '&.Mui-checked': {
            color: green[600],
          },
        }}
        name="roleId" value = "2"
        onChange={handleInputChange}
      />
        Admin   <Checkbox
        {...label}
        sx={{
          color: pink[800],
          '&.Mui-checked': {
            color: pink[600],
          },
        }}
        name="roleId" value = "1"
        onChange={handleInputChange}
      />
      </div>
            <div> 
     Active   <Checkbox
        {...label}
        sx={{
          color: green[800],
          '&.Mui-checked': {
            color: green[600],
          },
        }}
        name="status" value = "active"
        onChange={handleInputChange}
      />
        Deactive   <Checkbox
        {...label}
        sx={{
          color: pink[800],
          '&.Mui-checked': {
            color: pink[600],
          },
        }}
        name="status" value = "deactive"
        onChange={handleInputChange}
      />
      </div>

        </Grid>

        <Grid item xs={12}>
        
          
        </Grid>
        <div style={{float:"center"}}>
                      <span style={{color:"red",marginRight:70,marginLeft:150}}>
                      <Controls.Button
                      variant="contained" color="success"
                    type="submit"
                    text="Update" />
                      </span>
                    
                      <Controls.Button
                    text="Reset"
                    //color="default"
                    color="error"
                    onClick={resetForm} />
                      </div>
        {message == "" ? " " :
                             <Stack sx={{ width: '100%' }} spacing={2}>
                                {message == "User registered successfully!" ?  <Alert severity="success">{message}</Alert>:
                             <Alert severity="error">{message}</Alert> 
                            
}
                             </Stack>
                             }
    </Grid>
</Form>
    )
}