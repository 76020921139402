import React, { useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";
import Input from "react-validation/build/input";
import { Grid,Paper, Avatar, TextField, Button, Typography,Link, createTheme, MuiThemeProvider } from '@material-ui/core'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import AuthService from "../../api/auth/auth.service";
import { Box } from "@mui/system";
import { Slider } from "@mui/material";
import LoadingSpinner from "./LoadingSpinner";
import "./styles.css";
const theme = createTheme({
  palette: {
      secondary: {
          main: '#0d6e11'
      }
    },
});

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const Login = () => {
  let navigate = useNavigate();

  const form = useRef();
  const checkBtn = useRef();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleLogin = (e) => {
    e.preventDefault();

    setMessage("");
    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      AuthService.login(username, password).then(
        () => {
          navigate("/");
          window.location.reload();
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setLoading(false);
          setMessage(resMessage);
        }
      );
    } else {
      setLoading(false);
    }
  };
  const paperStyle={padding :50,height:'30vh',width:400, margin:"20px auto"}
  const avatarStyle={backgroundColor:'green'}
  const btnstyle={margin:'8px 0'}
  return (
 <Grid>
    <Paper elevation={10} style={paperStyle}>
        <Grid align='center'>
             <Avatar style={avatarStyle}><LockOutlinedIcon/></Avatar>
            <h2>Sign In</h2>
        </Grid>
        
        <Form onSubmit={handleLogin} ref={form}>
    <Grid><TextField label='Username' placeholder='Enter username' variant="outlined"   name="username"  value={username}onChange={onChangeUsername} fullWidth required/>
    </Grid>
    <Box mt={1}>

          </Box>
    <Grid><TextField label='Password' placeholder='Enter password' type='password' variant="outlined"  value={password}
               name="password" onChange={onChangePassword} fullWidth required/> </Grid>
          <MuiThemeProvider theme={theme}>
          <Button type='submit' color='secondary' variant="contained" style={btnstyle} fullWidth>
            
          {loading && (
               <LoadingSpinner />
              )}
              <span>Sign in</span>
            
            </Button>
            </MuiThemeProvider>
          {message && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
    </Paper>
</Grid>
  );
};

export default Login;