import React from 'react';
import { Grid, Typography } from '@mui/material'
import Button from '../../controls/Button'
import Input from '../../controls/Input'
import { Form, useForm } from '../../controls/useForm'
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import AssignmentQuery from '../../api/assignment/assignment.service';
import AuthService from '../../api/auth/auth.service';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns"; // import
import moment from "moment";
import { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { pink } from '@mui/material/colors';
export default function AssignmentForm(props) {
    const { addOrEdit, recordForEdit } = props
    const {saveAssignment,message,setMessag} = AssignmentQuery();
    const currentUser = AuthService.getCurrentUser();
    const [selectedDate, setDate] = React.useState(moment());
    const [inputValue, setInputValue] = useState(moment().format("YYYY-MM-DD"));
  console.log("moment" + selectedDate);
  const onDateChange = (date, value) => {
    setDate(date);
    setInputValue(value);
    console.log("kaba" + value)
  };
  const dateFormatter = str => {
    return str;
  };
    const initialFValues = {
        userId: currentUser.id,
        name: '',
        routing:'',
        fltType : '',
        selectedDate: inputValue,
    }
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  
 
    const validate = (fieldValues = values) => {
        let temp = { ...errors }
            if ('name' in fieldValues)
            temp.name = fieldValues.name ? "" : "This field is required."
            if('routing' in fieldValues)
            temp.routing = fieldValues.routing ? "" : "This field is required."
        setErrors({
            ...temp
        })
       
        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    }
    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        
        resetForm
    } = useForm(initialFValues, true, validate);
    const handleSubmit = e => {
        e.preventDefault()
        if (validate()) {
            saveAssignment(values,inputValue);
        console.log(values);
         resetForm();
        
        }
    }

  
return (
        <Form onSubmit={handleSubmit}>
            <Grid container>
                <Grid item xs={12}>
                FLT No/Date
                <Typography  style={{ marginLeft: `10px`,color:'black'}}>
                    
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
         autoOk={true}
         value={selectedDate}
         format="yyyy-mm-dd"
         inputValue={inputValue}
         onChange={onDateChange}
         rifmFormatter={dateFormatter}
            />
    </MuiPickersUtilsProvider>
    </Typography>
                    <Input
                        name="name"
                        label="Please Enter the name"
                        value = {values.name}
                        onChange={handleInputChange}
                        error = {errors.name}
                    />
                    
                    <Typography>
                        <Typography>FLT Type</Typography>
                        Freighter   <Checkbox {...label}   name="fltType" value = "Freighter" label="Label"   onChange={handleInputChange}/>
    
    Pax   <Checkbox
         {...label}
         sx={{
           color: pink[800],
           '&.Mui-checked': {
             color: pink[600],
           },
         }}
         name="fltType" value = "Pax"
         onChange={handleInputChange}
       />
       </Typography>
                      <Input
                        name="routing"
                        label="Please Enter the routing"
                        value = {values.routing}
                        onChange={handleInputChange}
                        error = {errors.routing}
                    />
                        <Input
                        name="bchName"
                        label="Please Enter the bchName"
                        value = {values.bchName}
                        onChange={handleInputChange}
                        error = {errors.bchName}
                    />
                    <div style={{float:"center"}}>
                      <span style={{color:"red",marginRight:70,marginLeft:120}}>
                      <Button
                       variant="contained" color="success"
                            type="submit"
                            text="Create New Assignment" />
                      </span>
                
                     <Button
                            text="Reset"
                            variant="contained" color="error"
                            onClick ={resetForm}
                             />
                      </div>
                             {message == "" ? " " :
                             <Stack sx={{ width: '100%' }} spacing={2}>
                                {message == "Assignment Already Exist" ?  <Alert severity="error">{message}</Alert> :
                            
                             <Alert severity="success">{message}</Alert>
}
                             </Stack>
                             }
                             
                </Grid>
            </Grid>
        </Form>
    )
}