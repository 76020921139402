import axios from "axios";
import baseURL from "../url";
const getAllAssignment = async () => {
    const data  = await axios.get(baseURL + '/api/allAssignment/');
        return data;
      };
      
      const getItemByAssignmentId = async (id) => {
        const data  = await axios.get(baseURL + '/api/itemsByAssignmetId');
            return data;
          };  
          const getAllCheckListAsseignmentById = async (id) => {
            const data  =  await  axios.get(baseURL + `/api/assignmetChckList/${id}`);
                return data;
              };
     const getAssignmentByDate = async (body) => {
      console.log("sadguru2");
      console.log(body);
      const createdDate = body;
      console.log("data");
     const data  =  await  axios.get(baseURL + '/api/getAssignmentByDate/',{
      params: {
        createdDate: createdDate
      }
      
     });
     return data;
                  };
              
  const updateCheckList =  async (body) => {
                const data  =  await axios.put(baseURL + '/api/updateCheckList',body);
                        return data;
                };
  const updateItem =  async (body) => {
                  const data  =  await axios.put(baseURL + '/api/updateItem',body);
                          return data;
                  };
const Item ={
    getAllAssignment,
    getItemByAssignmentId,
    updateCheckList,
    getAssignmentByDate,
    updateItem,
}
export default Item;


