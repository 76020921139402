import axios from "axios";
import {useEffect, useState } from "react";
import baseURL from "../url";
import Item from "./item";
const ItemQuery = () => {
  const[items,setItems] = useState([]);
  const [message,setMessag] = useState("");
  console.log("bacha");
  const getItemByAssignmentId =  (data) => {
    const  id=data;
     Item.getItemByAssignmentId(id)
       .then((response) => {
         setItems(response.data);
         console.log(response.data);
         console.log("mami")
       })
       .catch((e) => {
         console.log(e);
       });
   };
   const itemList = items.map((row) => (
    [row.sn,row.pallet_number,row.awb_number,row.piece,row.weight,row.remark,row.createdAt]
    ));
      const saveItem= async (data) =>{
       console.log(data);
        const sn = data.sn;
        const pallet_number = data.pallet_number;
        const   awb_number = data.awb_number;
        const  piece = data.piece;
        const  weight = data.weight;
        const remark = data.remark;
        const assignmentId = data.assignmentId;
        try {
          await axios.post(baseURL + '/api/saveItem', {
            sn,pallet_number,awb_number,piece,piece,weight,remark,assignmentId
          }
          );
        //  Service.saveService();
        } catch (error) {
          console.log(error.response);
         setMessag(error.response.message)
          // Check if it's HTTP 400  error
          if (error.response.status === 400) {
            console.log(`HTTP 400 error occured`);
          }
          // You can get response data (mostly the reason would be in it)
          if (error.response.data) {
            console.log(error.response.data);
            setMessag(error.response.data.message)

          }
          // throw error if you want to handle it somewhere
          // throw error;
        }

      }
      const updateItem = (data) => {
        Item.updateItem(data) .then((response) => {
            console.log(response.data);
          })
          .catch((e) => {
            console.log(e);
          }); 
      };

      useEffect(()=>{
        getItemByAssignmentId();
      },[])

    return {
      saveItem,
      getItemByAssignmentId,
      itemList,
      message,
      updateItem,
    };
  };
  
  export default ItemQuery;