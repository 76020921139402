import axios from "axios";
import baseURL from "../url";

const gtAllUsers = async () => {
     const data  = await axios.get(baseURL + "/api/allEmployees");
            return data;
    };
const saveUser = (data) => {
    const username = data.username
    const email = data.email
    const password = data.password
    const firstName = data.firstName
    const lastName = data.lastName
    const windowNumber = data.windowNumber
    const role = 2

    return  axios.post(baseURL + "/api/auth/signup",{
     username,
     email,
     password,
     firstName,
     lastName,
     windowNumber,
     role
    });
    };
const updateUser = () => {
        return  axios.put(baseURL + "/api/updateService");
        };
      
const dleteUser = () => {
    return  axios.get(baseURL + "/api/allBranche");
    };
    const updateUserDetail =  async (body) => {
        const data  =  await axios.put(baseURL + '/api/updateUserDetail',body);
                return data;
        };
        const userById = async (id) => {
            const data  =  await  axios.get(baseURL + `/api/userById/${id}`);
                return data;
              };
const User ={
    gtAllUsers,
    saveUser,
    updateUser,
    dleteUser,
    updateUserDetail,
    userById
}
export default User;


