import axios from "axios";
import { useEffect, useState } from "react";
import baseURL from "../url";
import Setting from "./setting.api";

const SettingQuery = () => {
const [message,setMessage] = useState("");
      const updatePassword = (body) => {
     try{ 
        axios.put(baseURL +  '/api/changePassword',body)
          .then(response => setMessage(response.data.message));
            }catch(error){
              setMessage({ errorMessage: error.message });
                  console.error('There was an error!', error);
              };
        };


    return {message,setMessage,updatePassword};
  };
  
  export default SettingQuery;