import axios from "axios";
import baseURL from "../url";
const API_URL = baseURL + "/api/auth/";
const register = (data) => {
   const username = data.username
   const email = data.email
   const password = data.password
    const firstName = data.firstName
   const lastName = data.lastName
   const windowNumber = data.windowNumber
   const role = 3


  return axios.post(API_URL + "signup", {
    username,
    email,
    password,
    firstName,
    lastName,
    windowNumber,
    role
  });
};
const createEmployee = (data) => {
  const username = data.username
  const email = data.email
  const password = data.password
   const firstName = data.firstName
  const lastName = data.lastName
  const windowNumber = data.windowNumber
  const role = 2

 return axios.post(API_URL + "signup", {
   username,
   email,
   password,
   firstName,
   lastName,
   windowNumber,
   role,
 });
};

const login = (username, password) => {
    console.log("wene" + username)
  return axios
    .post(API_URL + "signin", {
      username,
      password,
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
};

const getCurrentUser = () => {
  if (typeof window !== 'undefined') {
    return JSON.parse(localStorage.getItem("user"));
    // 👉️ can use localStorage here
  } else {
    console.log('You are on the server')
    // 👉️ can't use localStorage
  }

 
};
const AuthService = {
    register,
    createEmployee,
    login,
    logout,
    getCurrentUser, 
}
export default  AuthService;



