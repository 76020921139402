import React from 'react';
import { Grid } from '@mui/material'
import { useEffect } from 'react'
import Controls from '../../controls/Controls'
import { Form, useForm } from '../../controls/useForm'
import UserQuery from '../../api/user/user.service';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
export default function UserForm(props) {
    const { addOrEdit, recordForEdit } = props
    const {saveUser,message,setMessage} = UserQuery();
    const initialFValues = {
        id: 0,
        firstName: '',
        lastName: '',
        email: '',
        window: '',
        username: '',
        password: '',
        confirmPassword: '',
    }
    const states = [
        {
          value: '1',
          label: 'Admin'
        },
        {
          value: '2',
          label: 'Employee'
        },
        {
          value: '3',
          label: 'Customer'
        }
      ];

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('firstName' in fieldValues)
            temp.firstName = fieldValues.firstName ? "" : "This field is required."
         if ('lastName' in fieldValues)
            temp.lastName = fieldValues.lastName ? "" : "This field is required."
      
         if ('email' in fieldValues)
            temp.email = fieldValues.email ? "" : "This field is required."
        if ('username' in fieldValues)
            temp.username = fieldValues.username ? "" : "This field is required."
        if ('password' in fieldValues)
            temp.password = fieldValues.password ? "" : "This field is required."
        if ('confirmPassword' in fieldValues)
            temp.confirmPassword = fieldValues.confirmPassword ? "" : "This field is required."
           
        
            
         
            
            setMessage("")
        setErrors({
            ...temp
        })

        if (fieldValues == values)
            return Object.values(temp).every(x => x == "")
            
    }
    const {
        values,
        setValues,
        errors,
        setErrors,
         handleInputChange,
        resetForm
    } = useForm(initialFValues, true, validate);
    const handleSubmit = e => {
        e.preventDefault()
        if (validate()) {
        saveUser(values)
        console.log(values);
         resetForm();
        }
    }
    useEffect(() => {
        if (recordForEdit != null)
            setValues({
                ...recordForEdit
            })
    }, [recordForEdit])
  
return (
    <Form onSubmit={handleSubmit}>
    <Grid container>

        <Grid item xs={6}>
            <Controls.Input
                name="firstName"
                label="First Name"
                value={values.firstName}
                onChange={handleInputChange}
                error={errors.firstName}
            />
           <Controls.Input
                name="lastName"
                label="Lst Name"
                value={values.lastName}
                onChange={handleInputChange}
                error={errors.lastName}
            />
           
           <Controls.Input
                label="password"
                name="password"
                value={values.password}
                onChange={handleInputChange}
                 error={errors.password}
            />
            

        </Grid>

        <Grid item xs={6}>
           

            <div>
            <Controls.Input
                label="Email"
                name="email"
                value={values.email}
                onChange={handleInputChange}
                error={errors.email}
            />
             <Controls.Input
                label="Username"
                name="username"
                value={values.username}
                onChange={handleInputChange}
                 error={errors.username}
            />
          
             <Controls.Input
                label="Confirm password"
                name="confirmPassword"
                value={values.confirmPassword}
                onChange={handleInputChange}
                 error={errors.confirmPassword}
            />
             
              
                    
            </div>
          
        </Grid>
        <div style={{float:"center"}}>
                      <span style={{color:"red",marginRight:70,marginLeft:150}}>
                      <Controls.Button
                       variant="contained" color="success"
                    type="submit"
                    text="Create New USer" />
                      </span>
                    
                      <Controls.Button
                    text="Reset"
                    //color="default"
                    variant="contained" color="error"
                    onClick={resetForm} />
                      </div>
        {message == "" ? " " :
                             <Stack sx={{ width: '100%' }} spacing={2}>
                                {message == "User registered successfully!" ?  <Alert severity="success">{message}</Alert>:
                             <Alert severity="error">{message}</Alert> 
                            
}
                             </Stack>
                             }
    </Grid>
</Form>
    )
}