import React from 'react';
import { Box, Container } from "@mui/material";
import { UserListResults } from "../../components/user/user-list-results";
import { UsersListToolbar } from "../../components/user/user-list-toolbar";
import AuthService from '../../api/auth/auth.service';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
export default function User(){
  const currentUser = AuthService.getCurrentUser();
  let navigate = useNavigate();
  if(currentUser){
    return (
      <>
       <Helmet>
        <title>Loadsheet</title>
    </Helmet>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8
        }}
      >
        <Container maxWidth={false}>
          <UsersListToolbar />
          <Box sx={{ mt: 3 }}>
     <UserListResults />
          </Box>
        </Container>
      </Box>
    </>

    );
  }else{
    navigate("/");
    window.location.reload();   
  }

}