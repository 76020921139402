import React, { useEffect, useState } from "react"
import { Box, Container, Grid, Typography } from "@mui/material";
import MUIDataTable from "mui-datatables";
import Popup from "../../controls/Popup";
import AssignmentQuery from "../../api/assignment/assignment.service";
import Button from "../../controls/Button";
import CheckListForm from "./checkListForm";
import AuthService from "../../api/auth/auth.service";
import moment from "moment/moment";

const CheackList = (props) =>{
  const  currentDate = moment().format("YYYY-MM-DD");
  
 const  currentuser = AuthService.getCurrentUser();
  const {checklistDetail,getAllCheckListAsseignmentById} = AssignmentQuery();
  const [openPopup, setOpenPopup] = useState(false);
  const [newData,setNewData] = useState();
  const id = props.id;
  console.log("id");
  console.log(id);
  console.log("fraole");
  useEffect(()=>{
    getAllCheckListAsseignmentById(id)
  },[])
return(
  <Box>
    <Grid item xs={12}>
      {
       currentuser.roles == "ROLE_AGENT" ? 
      
(
  currentDate === currentDate ?
  <MUIDataTable
  data={checklistDetail}
  columns={["id", "name","Status", {
    name: "Edit",
    options: {
      filter: true,
      sort: false,
      empty: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
             <Button
             text="Edit"
             variant="outlined"
             onClick={() => { setOpenPopup(true);setNewData(tableMeta.rowData);}}
         />

        );
      }
    }
  },]}
  options={{
    filterType: "checkbox",
  }}
/> 
  :
  <MUIDataTable
  data={checklistDetail}
  columns={["id", "name","Status"]}
  options={{
    filterType: "checkbox",
  }}
/> 
):
<MUIDataTable
  data={checklistDetail}
  columns={["id", "name","Status"]}
  options={{
    filterType: "checkbox",
  }}
/> 
}
</Grid>
     <Popup  
                title="Edit Cheak List"
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
            >
               <CheckListForm data = {newData}/>
 </Popup>
  </Box>
  

)
}
export default CheackList;
