import axios from "axios";
import React, { useEffect, useState } from "react";
import Child from "./Child"

function ReactExample(props) {
    const [number1, setNumber1] = React.useState();
    const [number2, setNumber2] = React.useState();
    const [total, setTotal] = React.useState(0);
    const [myData,setMyData] = useState([])
  
    const [count,setCount]=useState(0);
    function calculateTotal() {
      setTotal(number1 + number2);
    }
  
    useEffect(()=>{
  axios.get("http://localhost:4050/api/allEmployees").then((response) => {
    setMyData(response.data);
  })
  .catch((e) => {
    console.log(e);
  });
  
    },[])
    return (
      <div>
        <h2>Adding Two Numbers</h2>
        <input
          placeholder="First Number"
          type="number"
          value={number1}
          onChange={(e) => setNumber1(+e.target.value)}
        />
        <input
          placeholder="Second Number"
          type="number"
          value={number2}
          onChange={(e) => setNumber2(+e.target.value)}
        />
  
        <button onClick={calculateTotal}>Add Two Numbers</button>
        <p>Total: {total || ""}</p>
        <button onClick={()=>{setCount(count + 1)}}>Count {count}</button>
        {
          myData.map((data)=><li {...data.id}>{data.username}</li>)
        }
      </div>
    );
  }
export default ReactExample;