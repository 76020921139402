import React from 'react';
import { useNavigate } from 'react-router-dom';
import AuthService from '../../api/auth/auth.service';
import { AccountProfile } from "../../components/account/account-profile";
import { AccountProfileDetails } from "../../components/account/account-profile-details";
export default function Account(){
  const currentuser = AuthService.getCurrentUser();
  let navigate = useNavigate();
  if(currentuser){ 
    return (
        <div>
<AccountProfile/>
<AccountProfileDetails/>
        </div>

      );
    }else{
      navigate("/");
      window.location.reload();

  }
}