import * as React from 'react';
import { Box  } from '@mui/material';
import MUIDataTable from 'mui-datatables'; 
import Popup from '../../controls/Popup';
import Button from '../../controls/Button';
import UserForm from './userForm';
import UserQuery from '../../api/user/user.service';
import AssignmentQuery from '../../api/assignment/assignment.service';
import UserEditForm from './user-edit-form';
export const UserListResults = () => {
  const [openPopup, setOpenPopup] = React.useState(false);
const {user,getUsers} = UserQuery(); 
const [newData,setNewData] =React.useState("")
getUsers();
 const serviceData = user.map((row) => (
       [row.id,row.firstName + " " + row.lastName,row.username,row.email,row.roles[0].name,row.status]          
          ));
          const columns = [
            {
              name: "#Id",
              options: {
                filter: true,
              }
            },
            {
              label: "Full Name",
              name: "Title",
              options: {
                filter: true,
              }
            },
            {
              name: "Username",
              options: {
                filter: true,
                sort: false,
              }
            },
            {
              name: "Email",
              options: {
                filter: true,
                sort: false,
              }
            },
            {
              name: "Role",
              options: {
                filter: true,
                sort: false,
              }
            },
            {
              name: "Action",
              options: {
                filter: true,
                sort: false,
              }
            },
            {
              name: "Edit",
              options: {
                filter: true,
                sort: false,
                empty: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                  return (
                  
                       <Button
                       text="Edit"
                       variant="outlined"
                       onClick={() => { setOpenPopup(true);setNewData(tableMeta.rowData);  }}
                   />

                  );
                }
              }
            },
          ];
  return (
    <Box sx={{ mt: 3 }}>
    <MUIDataTable
           title="Servie"
           data={serviceData}
           columns={columns}
           options={{
             filterType: "checkbox",
           }}
         />
          <Popup
                title="User Form"
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
            >
         
            <UserEditForm      id = {newData[0]} role ={newData[4]} status={newData[5]} />
         </Popup>
       </Box>
  );
};
